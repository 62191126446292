<template>
  <div class="job-apply-page wrapper page-wrap">
    <a-spin :spinning="loadingForm">
      <a-form :model="form" ref="formRef" :colon="colon" @finish="handleSubmit">
        <div class="job-wrap">
          <a-row type="flex" justify="space-between" :gutter="20">
            <a-col :span="12">
              <a-form-item
                name="job_company_name"
                label="公司名称"
                :rules="{
                  required: true,
                  message: '请输入'
                }"
              >
                <a-input
                  v-model:value="form.job_company_name"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="job_contact_name"
                label="联系人"
                :rules="{
                  required: true,
                  message: '请输入联系人'
                }"
              >
                <a-input
                  v-model:value="form.job_contact_name"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="job_contact_phone"
                label="联系电话"
                :rules="{
                  required: true,
                  message: '请输入联系电话'
                }"
              >
                <a-input
                  v-model:value="form.job_contact_phone"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="job_contact_email"
                label="联系邮箱"
                :rules="{
                  required: true,
                  message: '请输入联系邮箱'
                }"
              >
                <a-input
                  v-model:value="form.job_contact_email"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="job_company_address"
                label="公司地址"
                :rules="{
                  required: true,
                  message: '请输入公司地址'
                }"
              >
                <a-input
                  v-model:value="form.job_company_address"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <div class="line"></div>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="job_name"
                label="职位名称"
                :rules="{
                  required: true,
                  message: '请输入职位名称'
                }"
              >
                <a-input
                  v-model:value="form.job_name"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-row>
                <a-col :span="13">
                  <a-form-item
                    name="job_salary_min"
                    label="薪资范围"
                    :rules="{
                      required: true,
                      message: '请输入最低薪资'
                    }"
                  >
                    <a-input
                      v-model:value="form.job_salary_min"
                      placeholder="请输入"
                      size="large"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="2"
                  ><div style="text-align: center; line-height: 2">
                    -
                  </div></a-col
                >
                <a-col :span="9">
                  <a-form-item
                    name="job_salary_max"
                    :rules="{
                      required: true,
                      message: '请输入最高薪资'
                    }"
                  >
                    <a-input
                      v-model:value="form.job_salary_max"
                      placeholder="请输入"
                      size="large"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="job_introduction"
                label="职位说明"
                :rules="{ required: true, message: '请输入内容' }"
              >
                <a-textarea
                  v-model:value="form.job_introduction"
                  size="large"
                  :auto-size="{ minRows: 4, maxRows: 4 }"
                  placeholder="输入内容......"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="job_num"
                label="招聘人数"
                :rules="{
                  required: true,
                  message: '请输入招聘人数'
                }"
              >
                <a-input
                  v-model:value="form.job_num"
                  placeholder="请输入"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="job_end_time"
                label="截止时间"
                :rules="{
                  required: true,
                  message: '请选择截止时间'
                }"
              >
                <a-date-picker
                  size="large"
                  v-model:value="form.job_end_time"
                  show-time
                  format="YYYY-MM-DD HH:mm:ss"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item class="">
                <a-button
                  block
                  size="large"
                  type="primary"
                  html-type="submit"
                  class="btn"
                  :loading="loadingBtn"
                  @click="handleSubmit"
                >
                  提交审核
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
  createVNode
} from "@vue/runtime-core";
import { setJobData, getJobData } from "@/api/main.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import url from "@/utils/url.js";
import { message, Modal } from "ant-design-vue";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "JobForm",
  components: {
    // ExclamationCircleOutlined
  },
  setup() {
    const formRef = ref();
    const route = useRoute();
    const pageData = reactive({
      colon: false,
      autosize: false,
      jobId: null,
      form: {
        job_company_name: null,
        job_contact_name: null,
        job_contact_phone: null,
        job_contact_email: null,
        job_company_address: null,
        job_name: null,
        job_salary_min: null,
        job_salary_max: null,
        job_introduction: null,
        job_num: null,
        job_end_time: null
      },
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData())
    });
    const getJobDataFun = _jobId => {
      getJobData({ job_id: _jobId })
        .then(res => {
          if (res.code == 200) {
            pageData.form = { ...res.data.job };
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const setJobDataFun = () => {
      formRef.value
        .validate()
        .then(res => {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          setJobData({
            ...res,
            user_id: pageData.user_id,
            job_id: pageData.jobId
          }).then(res => {
            message.success("提交成功");
            url.replaceTo("/job/myJob");
          });
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    const handleSubmit = e => {
      e.preventDefault();
      if (pageData.jobId) {
        Modal.confirm({
          title: "提示",
          icon: createVNode(ExclamationCircleOutlined),
          content:
            "修改后将自动变为待审核状态，需平台审核后才会显示，是否确定？",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            setJobDataFun();
          }
        });
      } else {
        setJobDataFun();
      }
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/job/form") {
          if (val?.t) {
            let query = decode(val?.t).split(",");
            if (query[0]) {
              pageData.loading = true;
              pageData.jobId = query[0];
              getJobDataFun(query[0]);
            } else {
              pageData.loading = false;
              pageData.job = null;
              message.error("暂无公告信息");
            }
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      ...toRefs(pageData),
      formRef,
      handleSubmit
    };
  }
});
</script>
<style lang="less">
.job-apply-page {
  .job-wrap {
    width: 960px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 40px auto;
    padding: 20px;
    box-sizing: border-box;
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin-bottom: 20px;
  }
}
</style>
